<template>
  <article class="bg-white rounded p-4 my-8 border shadow hover:shadow-2xl">
    <div
      class="-mt-4 -ml-4 pl-4 w-48 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"
    >
      <!-- <span class="mr-1"><fa :icon="['fal', 'user']"/></span> -->
      {{ $t('right_card_label') }}
    </div>
    <div class="flex">
      <div class="flex-1 my-4 text-2xl">
        <div class="text-2xl lg:text-4xl font-semibold pt-2 p-2">
          <router-link
            :to="{
              name: 'right',
              params: {
                cid: right.companyId,
                aid: right.accountId,
                rid: right.id,
              },
            }"
          >
            <span>{{ right.weekYear }}</span>
          </router-link>
        </div>
      </div>
      <div class="flex-auto">
        <summaries :summaries="summaries" />
      </div>
    </div>
    <div v-if="isManagerRW">
      <div class="flex justify-end">
        <sqr-button
          class="mr-2"
          icon="trash"
          tooltip="buttons_remove"
          @click="remove(right)"
          :is-loading="removing[right.id]"
        />
        <status-buttons
          :status="right.status"
          :is-loading="moderating[right.id]"
          @change="moderate(right, $event)"
          :options="['pending', 'accepted']"
        />
      </div>
      <sqr-error-note class="mt-2" :error="moderateError[right.id]" />
      <sqr-error-note class="mt-2" :error="removeError[right.id]" />
    </div>
  </article>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';
import StatusButtons from './StatusButtons';
import Summaries from './Summaries';

export default {
  name: 'RightCard',
  components: { SqrErrorNote, SqrButton, StatusButtons, Summaries },
  props: {
    right: Object,
  },
  computed: {
    ...mapGetters('perms', ['isManagerRW']),
    ...mapState('moderate', ['moderating', 'moderateError']),
    ...mapState('remove', ['removing', 'removeError']),
    summaries() {
      if (!this.right) return [];
      return [
        {
          op: 'previous',
          icon: 'forward',
          name: this.$t('right_vacations_previous_short'),
          day: this.right.vacationsPrevious,
        },
        {
          op: 'contact',
          icon: 'bullseye',
          name: this.$t('right_vacations_contract_short'),
          day: this.right.vacationsByContract,
        },
        {
          op: 'total',
          icon: 'sigma',
          name: this.$t('right_vacations_short'),
          day: this.right.vacations,
        },
      ];
    },
  },
  methods: {
    ...mapActions('moderate', ['mod']),
    ...mapActions('remove', ['rm']),
    moderate(right, status) {
      this.mod({ type: 'right', doc: right, status });
    },
    remove() {
      const right = this.right;
      const payload = { name: name(right) ?? right.id };
      if (confirm(this.$t('right_remove_confirm', payload))) {
        this.rm({ doc: right });
      }
    },
  },
};
</script>
