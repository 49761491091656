<template>
  <main class="bg-gray-200">
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <!-- prettier-ignore -->
        <nav
          class="breadcrumb"
          aria-label="breadcrumbs"
        >
          <ul>
            <router-link tag="li" :to="{ name: 'home' }" ><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'companies' }" ><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }" ><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }" ><a><account-name :account="account"/></a ></router-link>
            <router-link tag="li" :to="{ name: 'rights', params: { cid, aid } }" ><a>{{ $t('breadcrumb_rights') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-show="loading" />
    <sqr-error :error="loadError" />
    <section class="px-2 md:px-0 py-4">
      <div class="columns is-centered">
        <div class="column is-12-mobile is-10-tablet is-8-desktop">
          <div class="is-clearfix">
            <div class="buttons is-pulled-right">
              <sqr-router-link
                icon="plus"
                label="rights_add"
                color="primary"
                size="medium"
                :to="{ name: 'right-add', params: { cid, aid } }"
              />
            </div>
          </div>
          <right-card v-for="right in records" :key="right.id" :right="right" />
        </div>
      </div>
    </section>
    <section class="px-4 md:px-0 pt-2 border-t bg-white sticky bottom-0">
      <div class="container">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import account from './account';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
import SqrFirePage from '@/sqrd/SqrFirePage';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import RightCard from '@/components/RightCard';

export default {
  name: 'Rights',
  mixins: [account],
  components: {
    SqrProgress,
    SqrError,
    SqrRouterLink,
    SqrFirePage,
    CompanyName,
    AccountName,
    RightCard,
  },
  computed: {
    ...mapState('rights', ['records', 'loading', 'loadError']),
    ...mapGetters('rights', ['pagination']),
    ...mapGetters('perms', ['isManager']),
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'accounts', this.aid, 'rights'],
      orderBy: [['weekYear', 'desc']],
    });
  },
  methods: {
    ...mapActions('rights', ['sub', 'pageSet', 'pageSize']),
  },
};
</script>
