var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"bg-white rounded p-4 my-8 border shadow hover:shadow-2xl"},[_c('div',{staticClass:"-mt-4 -ml-4 pl-4 w-48 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"},[_vm._v(" "+_vm._s(_vm.$t('right_card_label'))+" ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1 my-4 text-2xl"},[_c('div',{staticClass:"text-2xl lg:text-4xl font-semibold pt-2 p-2"},[_c('router-link',{attrs:{"to":{
            name: 'right',
            params: {
              cid: _vm.right.companyId,
              aid: _vm.right.accountId,
              rid: _vm.right.id,
            },
          }}},[_c('span',[_vm._v(_vm._s(_vm.right.weekYear))])])],1)]),_c('div',{staticClass:"flex-auto"},[_c('summaries',{attrs:{"summaries":_vm.summaries}})],1)]),(_vm.isManagerRW)?_c('div',[_c('div',{staticClass:"flex justify-end"},[_c('sqr-button',{staticClass:"mr-2",attrs:{"icon":"trash","tooltip":"buttons_remove","is-loading":_vm.removing[_vm.right.id]},on:{"click":function($event){return _vm.remove(_vm.right)}}}),_c('status-buttons',{attrs:{"status":_vm.right.status,"is-loading":_vm.moderating[_vm.right.id],"options":['pending', 'accepted']},on:{"change":function($event){return _vm.moderate(_vm.right, $event)}}})],1),_c('sqr-error-note',{staticClass:"mt-2",attrs:{"error":_vm.moderateError[_vm.right.id]}}),_c('sqr-error-note',{staticClass:"mt-2",attrs:{"error":_vm.removeError[_vm.right.id]}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }